import revive_payload_client_mMNs2iTYv5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9UiEfm4jsX from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_po24bUNs99 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_N0DQXKrgmd from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.9_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_ter_rwxhewlyb75wby2jdxrt3glejq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import friendly_challenge_client_IEpiLhgyLW from "/vercel/path0/plugins/friendly-challenge.client.ts";
import payload_client_mdQL50tLA7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mS4GyfaNgJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pWHKBO06nX from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mKqUtrUZ4J from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_DSrv0C4Omh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tbptujghdugkltcrizhthik66i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_DWGiyM1uYQ from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.9.0_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_terser@5.31._mpj6o6gmxcetf7mhr72l2d7atu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
export default [
  revive_payload_client_mMNs2iTYv5,
  unhead_9UiEfm4jsX,
  router_po24bUNs99,
  _0_siteConfig_N0DQXKrgmd,
  friendly_challenge_client_IEpiLhgyLW,
  payload_client_mdQL50tLA7,
  navigation_repaint_client_mS4GyfaNgJ,
  check_outdated_build_client_pWHKBO06nX,
  chunk_reload_client_mKqUtrUZ4J,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DSrv0C4Omh,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_DWGiyM1uYQ
]